export const totoAfricaLyrics = `
게임이론
연필로 과제해도 되나요?? ㅠㅠ

5분 전
익명
데사기 왜 제출만 하면 오류나냐
개인환경에선 잘돌아가는데 맨날이래
에러코드도 잘 안나오니까 어디가 잘못됐는지도 모르겠음

2
54분 전
익명
책 무료나눔 합니다
심리학 관련 수업들을때 참고했던 책 무료나눔 합니다. 거의 새책입니다.
다만 제가 졸업생이라 학교 기숙사말고 학교운동장 쪽 앵무새카페에서 거래 가능하신 분 쪽지주세요!

1
13:56
익명
학잠 빨래 돌려도 이염 ㄱㅊ?
궁금

1
13:12
익명
와이파이
E동 와이파이 연결 되는사람?

12:55
익명
UCLA fglp 수강신청
6주하고 8주하고 수강신청할 수 있는 과목이 다른가요?

1
11:46
익명
데사기 3번..
어제밤부터 풀고 있는데 안돼요.. 도와주실수있는 분 쪽지부탁드려요ㅜㅜㅜ

11:21
익명
일생1 드랍
생명트랙 타려고 하긴 하는데 지금 일생까지 듣기 좀 힘드네요 (배우면서 회의감듦) 2학기 때 들어도 과목(일생2 같은거) 밀리거나 불이익 같은거 있을까요??

9
10:00
익명
데사기..과제
질문 1번만 드려도 될까요...

2
09:12
익명
fglp 서류
1300자 꽉채워야되나요? 너무 쓸게없는데

3
01:16
익명
⚽️DGIST 풋살 대회 개최⚽️
안녕하세요! 디지스트 축구동아리 디핏, 풋살동아리 FC참꽃입니다. 2024년 교내 풋살대회를 개최하려고 합니다.
1. 일시: 3월 20일 수요일(8강 4경기), 3월 27일 수요일(4강 2경기, 3,4위전, 결승) 18시-22시
2. 참가 자격: 디지스트 학부생, 대

94
00:08
익명
유기
들을만함?

5
03/14
익명
ㅅㄱㄹ 화학
ㅅㄱㄹ화학 과제 교수님이랑 ta 두 분 중에 어디에 제출해야해요?

1
03/14
익명
게임이론 과제
2 3챕터 홀수번 문제만 푸는거 맞죠??

2
03/14
익명
기숙사 도서실
긱사 로비에 있는 도서실 예약없이 써도 되는건가요?

1
03/14
익명
아이셔 청사과
이 근처에 아이셔 청사과맛 파는 술집 있나요?

1
03/14
익명
작년 UCLA 입소일
작년 ucla 입소일이 개강일 몇일 전인지 궁금합니다!! 항공권 미리 끊고 싶어서요
그리고 퇴소는 종강 후 언제까지 하면 되나요?

6
03/14
익명
기숙사에 후시딘 받을 수 있는데 있나요?
상처나서 바를려 하는데 보건실마냥 받을 수 있는데 있나요?
아님 편의점에 팔려나..?

4
03/14
익명
학정 스터디룸에서
생일파티 하는게 맞냐? ㅋㅋㅋㅋㅋㅋ
노래부르고 소리지르고 난리났네 진짜 어이터진다 ㅋㅋㅋㅋ

4038
03/14
익명
`