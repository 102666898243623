import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

import { lerp } from './customlib/func.js';
import WordCloud from './customlib/wordcloud.tsx';

function App() {
  const [gptmsg, setGptmsg] = useState('');
  const [response, setResponse] = useState('Hello User!');

  const [loading, setLoading] = useState(false);
  
  const gptSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    let resp;
    try {
      resp = await axios.post('https://serverctrl.chekspace.net/api/chat', { prompt: gptmsg });
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      setResponse(resp.data);
    }
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const updatewordcloud = () => {
    // console.log("hi");
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const intervalId = setInterval(updatewordcloud, 1000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(intervalId);
    };
  }, []);

  const animEndScrollpos = 800;
  const titleSize = lerp(60, 24, scrollPosition/animEndScrollpos);
  const titleSpacing = lerp(-4, -2.3, scrollPosition/animEndScrollpos)

  // Calculate content opacity based on scroll position
  const contentOpacity = scrollPosition > 200 ? 1 : scrollPosition / 200;

  return (
    <div className="App">
      <header className="App-header" style={{position: 'fixed', width: '100%', zIndex: 1000}}>
        <div id="title"
          style={{ fontSize: `${titleSize}px`, letterSpacing: `${titleSpacing}px`, transition: 'font-size 0.5s ease' }}
        >
          ChekSpace
        </div>
        <div id="subtitle">
          Gradually becoming competent
        </div>
      </header>
      <section
        style={{ height: '70vh' }}
        id="wordcloud"
      >
        <WordCloud />
      </section>
      <section className="App-content" style={{opacity: contentOpacity, transition: 'opacity 2s'}}>
        <section id="gptsection">
          <form onSubmit={gptSubmit}>
            <input
              type="text"
              value={gptmsg}
              onChange={(e) => setGptmsg(e.target.value)}
            />
            <button type="submit">Send</button>
          </form>
          <div>
            {
              loading ?
              <div id="loading">
                <div className="loading-icon"></div>
                <div>loading...</div>
              </div>
              : <div>{response}</div>
            }
          </div>
        </section>
        <section className="App-info">
          <p>Life Motto: "Gradually becoming competent"</p>
          <p>DGIST 21 CS & EE</p>
          <p>Born in 2002</p>
        </section>
        <section className="App-projects">
          <h1>My Coding Projects</h1>
          <ul>
            <li>University homepage Auto Login Extension</li>
            <li>3D Graphic Engine made from scratch</li>
            <li>AI Genie applied in Minecraft</li>
            <li>Quantum Simulator in Minecraft</li>
          </ul>
        </section>
      </section>
    </div>
  );
}

export default App;
